// Route guard middleware for auth - used on platforms pages behind the login strictly requiring login
export default defineNuxtRouteMiddleware(async(to) => {
  const authStore = useAuthStore();
  const {
    user,
    isUserLogged,
  } = storeToRefs(authStore);
  const { getUser, } = authStore;
  const uiStore = useUiStore();
  const {
    redirecting,
  } = storeToRefs(uiStore);

  try {
    // allowed routes without auth
    const allowedRoutesOrParams = [to.query.confirm, to.query.cancel,];
    if (allowedRoutesOrParams.some(param => param)) {
      return true;
    }

    if (!user.value) {
      await getUser();
    }

    if (!isUserLogged.value) {
      throw new Error('User not logged in');
    }
    return true;
  } catch (err) {
    redirecting.value = true;
    setTimeout(() => {
      redirecting.value = false;
    }, 2000);
    return navigateTo('/');
  }
});
